import React from 'react'

export default function PolicyTh(props) {
  return (
    <div className="fade-in" lang={`${props.lang}`}>
      <section id="term"></section>
      <div className="h-24 max:h-36 2xl:h-32 xl:h-28 lg:h-20 md:h-36 sm:h-28"></div>
      <div className="container-mobile mx-auto">
        <div>
          <div className="container-mobile mx-auto">
            <div
              className="
                                text-body-header font-bold
                                text-size-20 max:text-size-45 2xl:text-size-40 xl:text-size-35 lg:text-size-30 md:text-size-30 sm:text-size-25
                        "
            >
              นโยบายความเป็นส่วนตัว
            </div>
            <br />
            <div className="h-6"></div>
            <div
              className="
                            text-body-header font-bold
                            text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                        "
            >
              นโยบายความเป็นส่วนตัวของ www.aappoint.me
            </div>
            <div className="h-4"></div>
            <div
              className="text-body-content-gray
                            text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                            leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                        "
            >
              <br />
              บริษัท แอพพ้อยท์เม้นท์ เอนี่แวร์ ("บริษัท") ซึ่งรวมถึงเว็บไซต์
              www.aappoint.me
              ตระหนักถึงความปลอดภัยของข้อมูลส่วนบุคคลและจะรักษาข้อมูลที่ได้รับจากท่านไว้อย่างเป็นความลับและ
              ปลอดภัยโดยใช้นโยบายความเป็นส่วนตัวเพื่อป้องกันความเสียหายที่อาจเกิดขึ้นกับท่าน
              บริษัทจึงมีนโยบายความเป็นส่วนตัวเพื่อป้องกันความปลอดภัยของข้อมูลส่วนตัวของท่านดังนี้
            </div>
            {/* =============================================================== */}
            <div className="h-6"></div>
            <div
              className="
                            text-body-header font-bold
                            text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                        "
            >
              การเก็บข้อมูลและการใช้ข้อมูลส่วนบุคคล
            </div>
            <div className="h-4"></div>
            <div
              className="text-body-content-gray
                            text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                            leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                        "
            >
              <br />
              เมื่อท่านใช้บริการผ่านทางเว็บไซต์ www.aappoint.me
              และลงทะเบียนบัญชีผู้ใช้ ท่านจะต้องให้ข้อมูลส่วนตัวกับทางเว็บไซต์
              เช่น ชื่อ ที่อยู่ อีเมล เบอร์โทรศัพท์ ข้อมูลบัญชีธนาคาร
              รวมถึงข้อมูลลักษณะการใช้งานเว็บไซต์ เป็นต้น
              บริษัทตกลงจะเก็บข้อมูลเหล่านี้ไว้เป็นความลับ
              และบริษัทอาจใช้ข้อมูลดังกล่าวเพื่อ
              <br />
              <br />
              <ul className="list-disc">
                <li>
                  ประมวลผลและบริหารการใช้งานเว็บไซต์ของท่าน รวมถึงผู้ใช้งานอื่น
                </li>
                <li>
                  สื่อสารกับท่านทางโทรศัพท์ อีเมล
                  หรือข้อความทางมือถือเกี่ยวกับการใช้งานเว็บไซต์
                </li>
                <li>
                  ตอบคำถามของท่านเกี่ยวกับการให้บริการลูกค้า
                  หรือกระทำการอื่นใดเพื่อตอบคำถามของท่านหรือตอบสนองกิจกรรมในเว็บไซต์อื่นๆของท่าน
                </li>
                <li>
                  สร้างการส่งเสริมการขายเฉพาะกลุ่มโดยการรวมข้อมูลส่วนตัวของท่านกับข้อมูลที่ไม่เป็นข้อมูลส่วนตัวของท่าน
                  เช่นจำนวนครั้งและประเภทการซื้อของท่าน หรือประโยชน์ใดๆ
                  ที่ท่านได้รับผ่านเว็บไซต์
                </li>
                <li>
                  จัดทำข้อเสนอการขายเฉพาะกลุ่มโดยพิจารณาจากข้อมูลการใช้งานเว็บไซต์ส่วนบุคคล
                </li>
              </ul>
            </div>
            {/* =============================================================== */}
            <div className="h-12"></div>
            <div
              className="
                            text-body-header font-bold
                            text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                        "
            >
              ระยะเวลาในการเก็บข้อมูล
            </div>
            <div
              className="text-body-content-gray
                            text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                            leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                        "
            >
              <br />
              บริษัทจะทำการเก็บรักษาข้อมูลที่ได้รับจากท่านเป็นอย่างดี
              ด้วยระบบรักษาความปลอดภัยที่ได้มาตรฐาน จนกว่าจะมีการแก้ไข
              เปลี่ยนแปลง หรือยกเลิกโดยท่าน
              <br />
            </div>
            {/* =============================================================== */}
            <div className="h-12"></div>
            <div
              className="
                            text-body-header font-bold
                            text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                        "
            >
              การเปิดเผยข้อมูลส่วนบุคคลให้แก่บุคคลภายนอก
            </div>
            <div className="h-4"></div>
            <div
              className="text-body-content-gray
                            text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                            leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                        "
            >
              <br />
              บริษัทจะไม่เปิดเผยข้อมูลส่วนบุคคลใดๆ ของท่านให้แก่บุคคลภายนอก
              เว้นแต่
              <ul className="list-disc">
                <li>ได้รับความยินยอมจากท่าน</li>
                <li>
                  เป็นการเปิดเผยให้กับผู้จัดงาน หรือร้านค้าต่าง ๆ ทั้งนี้
                  เฉพาะข้อมูลส่วนบุคคลที่เกี่ยวข้องกับงานหรือบริการที่เกี่ยวข้อง
                </li>
                <li>
                  เป็นการเปิดเผยให้กับบริการอื่นซึ่งสนับสนุนการใช้งานเว็บไซต์
                  เช่น ผู้ให้บริการด้านการชำระเงิน หรือ ผู้ให้บริการอีเมล
                  เป็นต้น
                </li>
                <li>
                  ข้อมูลบางส่วนอาจเปิดเผย เมื่อมีคำร้องขอโดยชอบด้วยกฎหมาย
                  จากหน่วยงานทางกฎหมาย หรือเพื่อปกป้องสิทธิ ทรัพย์สิน
                  และความปลอดภัยของบริษัท พนักงาน ตัวแทน
                  และผู้ใช้งานเว็บไซต์ท่านอื่น เป็นต้น
                </li>
                <li>
                  กรณีมีการโอนกิจการ ควบรวมกิจการ การขายสินทรัพย์
                  หรือการกู้ยืมเงิน
                  บริษัทอาจต้องเปิดเผยข้อมูลบางส่วนให้กับผู้ที่เกี่ยวข้องในการทำธุรกรรมดังกล่าวทราบ
                </li>
              </ul>
            </div>
            {/* =============================================================== */}
            {/* =============================================================== */}
            <div className="h-12"></div>
            <div
              className="
                            text-body-header font-bold
                            text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                        "
            >
              การยกเลิกหรือเพิกถอนความยินยอม
            </div>
            <div
              className="text-body-content-gray
                            text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                            leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                        "
            >
              <br />
              ท่านสามารถยกเลิก หรือเพิกถอนความยินยอมในการเก็บ รวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของตนได้ ด้วยการดำเนินการตามขั้นตอนดังต่อไปนี้
              <br />
              <br />
              ส่งอีเมลล์โดยระบุข้อความว่าท่านมีความต้องการยกเลิกหรือเพิกถอนความยินยอมในการเก็บ รวบรวม ใช้ หรือเผยแพร่ข้อมูลของตน จากนั้นส่งมายังอีเมลของบริษัท
              <br />
              [customerservice@aappoint.me]
              <br />
            </div>
            {/* =============================================================== */}
            <div className="h-12"></div>
            <div
              className="
                            text-body-header font-bold
                            text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                        "
            >
              การแก้ไขนโยบายความปลอดภัยและข้อปฏิเสธความรับผิดชอบ
            </div>
            <div className="h-4"></div>
            <div
              className="text-body-content-gray
                            text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                            leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                        "
            >
              <br />
              บริษัทขอสงวนสิทธิในการเปลี่ยนแปลงนโยบายความเป็นส่วนตัวและข้อปฏิเสธความรับผิดชอบได้ทุกเมื่อ
              เนื้อหาทั้งหมดในหน้าเว็บไซต์นี้เป็นเนื้อหาที่ปรับปรุงล่าสุด
              ทางบริษัทจะแจ้งข้อมูลใหม่ให้ท่านทราบเมื่อมีการปรับปรุงข้อมูลเพื่อท่านจะได้ทราบถึงวิธีการป้องกันข้อมูล
              ส่วนตัวของลูกค้า
            </div>
            {/* =============================================================== */}
            <div className="h-12"></div>
            <div
              className="
                            text-body-header font-bold
                            text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                        "
            >
              การคุ้มครองความปลอดภัยในการเข้าถึงข้อมูลส่วนบุคคล
            </div>
            <div className="h-4"></div>
            <div
              className="text-body-content-gray
                            text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                            leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                        "
            >
              <br />
              บริษัทมีการดำเนินการตามสมควรในการรักษาความปลอดภัยด้านกายภาพ เทคนิค
              และการจัดการเพื่อป้องกันความสูญเสีย การใช้งานอันไม่สมควร
              การเข้าถึงข้อมูลโดยไม่ได้รับอนุญาต
              การเปิดเผยหรือปรับเปลี่ยนข้อมูลส่วนบุคคล อย่างไรก็ตาม
              <br />
              <br />
              บริษัทขอสงวนความรับผิดในการคุ้มครองความปลอดภัยดังกล่าว
              เนื่องจากไม่มีระบบหรือการส่งข้อมูลใดทางเครือข่ายสาธารณะใดที่จะสามารถรับประกันความปลอดภัยได้โดยสมบูรณ์
            </div>
            {/* =============================================================== */}
            <div className="h-12"></div>
            <div
              className="
                            text-body-header font-bold
                            text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                        "
            >
              ติดต่อเรา
            </div>
            <div className="h-4"></div>
            <div
              className="text-body-content-gray
                            text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                            leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                        "
            >
              <br />
              หากต้องการข้อมูลเพิ่มเติม คำถาม หรือคำแนะนำ ติชม
              ในข้อตกลงการใช้บริการ โปรดติดต่อเรา
              <br /><br />
              บริษัท แอพพ้อยท์เม้นท์ เอนี่แวร์ จำกัด (สำนักงานใหญ่)
              <br />
              239/2 อาคารลุมพินี 1 ห้อง 52 ชั้น 5 ถนนราชดำริห์
              <br />
              แขวงลุมพินี เขตปทุมวัน กรุงเทพฯ 10330
              <br /><br />
              ได้ในเวลาทำการของผู้ให้บริการตั้งแต่เวลา 09.00 - 17.00
              <br />
              หรือทางหมายเลขโทรศัพท์ 02-057-9135 หรือทางอีเมลล์ customerservice@aappoint.me
            </div>
            {/* =============================================================== */}
          </div>
        </div>
      </div>
    </div>
  )
}
